// libraries
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { slugify, handleAnonymousContentLiked } from '/lib/reactutils/utils.js'


// components
import Section from '/react4xp/components/Section'
import Input from '/react4xp/components/Form/Input'
import Button from '/react4xp/components/Button'
import ArticleCard from '/react4xp/components/ArticleCard'
import Link from '/react4xp/components/Link'
import Image from '/react4xp/components/Image'

// svg
import { GeneralChat, GeneralSearch, GeneralStar, GeneralStarFull } from '/react4xp/components/Svg/Icons/General'
import { UxArrowDown, UxArrowRight } from '/react4xp/components/Svg/Ux/Arrow'
import RichText from '../RichText'

interface MinKavlifavorittOverviewPartProps {
    title?: string
    intro?: string
    image?: {
        src: string
        alt: string
    }
    cta?: {
        href: string
        text: string
		target: boolean
    }
    filters: {
        label: string
        value: string
    }[]
    endpoint: string
    likedMinKavlifavoritt: string[]
	hasMinKavliFavoritt: boolean
    startValues: {
        page?: string
        search?: string
        filters?: string[]
        pageSize?: number
    }
    images: {
        base: string
        lights: string
        logoLight: string
        logoDark: string
        mask: string
    }
    strings: {
        ideasGallery: string
        sortBy: string
        searchLabel: string
        search: string
        noIdeasFound: string
        ideas: string
    }
	services: {
        likeContent: string
    }
    isLogged: boolean
}

export default function MinKavlifavorittOverview({title, intro, image, cta, filters, endpoint, likedMinKavlifavoritt, startValues, images, strings, hasMinKavliFavoritt, services, isLogged} : MinKavlifavorittOverviewPartProps) {
	const [minKavlifavorittArray, setMinKavlifavorittArray] = useState<any[]>([])
	const [total, setTotal] = useState(0)
    const [page, setPage] = useState(startValues?.page || 1)
    const [inputValue, setInputValue] = useState(startValues?.search || '')
	const [searchTerm, setSearchTerm] = useState(startValues?.search || '')
    const [selectedFilters, setSelectedFilters] = useState<any[]>(startValues?.filters && filters.filter((filter)=> startValues.filters.includes(filter.value)) || [])
    const [hasNextPage, setHasNextPage] = useState(false)
    const [loadMore, setLoadMore] = useState(false)

    useEffect(() => {
        handleFetchArticles()
	}, [searchTerm, selectedFilters, page])

    const handleFetchArticles = async ()=> {
        
        if(!endpoint) return;
        
        const origin = window.location.origin;
        const url = new URL(`${origin}${endpoint}`);
        const queryParams = new URLSearchParams();

        if (page) queryParams.append('page', String(page));
        if (startValues?.pageSize) queryParams.append('pageSize', String(startValues.pageSize));
        if (searchTerm) queryParams.append('search', searchTerm);
        if (selectedFilters.length) {
            selectedFilters.forEach(filter => {
                queryParams.append('filters', filter.value);
            });
        }
    
        url.search = queryParams.toString();

        updateCurrentUrl()
        
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            const data = await response.json();
            
            if(loadMore){
                setMinKavlifavorittArray([...minKavlifavorittArray, ...data.hits]);
                setLoadMore(false)
            }else{
                setMinKavlifavorittArray(data.hits);
            }

            setHasNextPage(data.hasNextPage);
			setTotal(data.total);

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    const handleSelectFilter = (filter) => {
        setPage(1);
        setSelectedFilters(prev => {
            const filterExists = prev.some(item => item.value === filter.value);
            if (filterExists) {
                return prev.filter(item => item.value !== filter.value);
            } else {
                return [...prev, { label: filter.label, value: filter.value }];
            }
        });
    };

	const handleSearchTerm = () => {
        setPage(1)
        setSearchTerm(inputValue)

        setTimeout(() => {
			const searchResultsElement = document.getElementById('search-results')
			if (searchResultsElement) {
				const headerOffset = 0
				const elementPosition = searchResultsElement.getBoundingClientRect().top + window.scrollY
				const offsetPosition = elementPosition - headerOffset
	
				window.scrollTo({
					top: offsetPosition,
					behavior: 'smooth'
				})
			}
		}, 100)
	}
    
	const handleLoadMoreMinKavlifavoritt = () => {
        if(hasNextPage){
            setLoadMore(true)
            setPage(Number(page) + 1)
        }
    }

    const updateCurrentUrl = () => {
        const origin = window.location.origin;
        const updatedUrl = new URL(`${origin}${window.location.pathname}`);
        const queryParams = new URLSearchParams();

        if (searchTerm) queryParams.append('search', searchTerm);
        if (selectedFilters.length) {
            selectedFilters.forEach(filter => {
                queryParams.append('filters', filter.value);
            });
        }

        updatedUrl.search = queryParams.toString();
        window.history.pushState({}, '', updatedUrl);
    
    }

	const [contentsLiked, setContentsLiked] = useState(likedMinKavlifavoritt || [])
	const toggleLike = async (event, minKavlifavoritt) => {
        event.target.disabled = true
        try {
            const response = await fetch(`${services?.likeContent}?contentId=${minKavlifavoritt.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    isLiked: !contentsLiked?.includes(minKavlifavoritt.id)
                })
            })

            const data = await response.json()

            if (response.ok) {
				minKavlifavoritt.likes = data.totalLikes

				if (data.isLiked) {
					setContentsLiked([...contentsLiked, minKavlifavoritt.id])
				} else {
					setContentsLiked(contentsLiked.filter(id => id !== minKavlifavoritt.id))
				}

                if (!isLogged) {
                    handleAnonymousContentLiked(minKavlifavoritt.id, data.isLiked)
                }
            }
        } catch (error) {
            console.log('error', error)
        }
        event.target.disabled = false
    }
	useEffect(() => {
		if (!isLogged) {
			const anonymousContentLiked = localStorage.getItem('anonymous-content-liked')
			if (anonymousContentLiked) {
				setContentsLiked(contentsLiked.concat(JSON.parse(anonymousContentLiked)))
			}
		}
	}, [])
	
	return (
		<main id='main' className='page-min-kavli--overview'>

			<Section
				bgColor='#F09B08'
				className='top-part'
				paddingTop='small'
				paddingBottom='medium'
			>
				<div className='container'>
					<div className='row'>

						{image && (
							<div className='col-md-6 col-lg-5 left'>
								<Image
									src={image.src}
									alt={image.alt}
									width={400}
								/>
							</div>
						)}

						<div className='col-md-6 col-lg-7 right'>

							{title && (
								<h1 className='h1-hero'>
									{title}
								</h1>
							)}

							{intro && (
								<RichText 
									className='text-big'
									content={intro}
								/>
							)}

							{cta && (
								<Button
									hasBorders
									color='blue'
									hover='red'
									href={cta.href}
									target={cta.target}
									noUnderline
								>
									{cta.text}
								</Button>
							)}

						</div>

					</div>
				</div>
			</Section>

			{
				hasMinKavliFavoritt &&
				<Section bgColor='white-dark' paddingBottom='big'>
					<div className='container'>

						<div className='top-search'>
							<div className='row'>
								<div className='col-lg-10 offset-lg-1 col-xl-8 offset-xl-2'>

									<h2 className='h2'>
										{strings.ideasGallery} <span>{total}</span> {strings.ideas}
									</h2>

									<p className='sub text-small'>
										{strings.sortBy}
									</p>

									<div className='top-search__filters'>

										{filters.map((filter, i) => (
											<Button
												key={i}
												hasBorders
												hover='white-dark'
												noUnderline
												smallBorders
												onClick={() => handleSelectFilter(filter)}
												className={clsx(
													selectedFilters.some(selectedFilter => selectedFilter.value === filter.value) ? 'active' : ''
												)}
											>
												{filter.label}
											</Button>
										))}

									</div>

									<div className='top-search__search'>
										
										<Input
											name={strings.searchLabel}
											id='search'
											size='large'
											label={strings.searchLabel}
											type='text'
											value = {inputValue}
											onChange={(e) => setInputValue(e.target.value)}
											onKeyDown={() => handleSearchTerm()}
										/>

										<Button
											color='black'
											hover='red'
											noUnderline
											smallBorders
											onClick={() => handleSearchTerm()}
										>
											<span>{strings.search}</span>
											<GeneralSearch variant='outline' />
										</Button>

									</div>

								</div>
							</div>
						</div>

						<div id='search-results' className='min-kavli-grid'>

							{minKavlifavorittArray.length > 0 ? (
								<div className='min-kavli-grid__wrapper'>
								
									{ minKavlifavorittArray.map((minKavlifavoritt) => (
										<div key={minKavlifavoritt.id} className='grid-item bg-white'>
											<a href={minKavlifavoritt.url?.href}>

												<div className='image'>

													<img src={images.base} alt='Min Kavlifavoritt' className='base' />
													<img src={images.lights} alt='Min Kavlifavoritt' className='lights' />

													<img 
														src={clsx(
															minKavlifavoritt.colorLight === false ? images.logoLight : images.logoDark
														)}
														alt='Min Kavlifavoritt'
														className='logos'
													/>

													<div className='texts'>
														<div className='texts-wrapper'>
															<span>{minKavlifavoritt.ingredients[0].name}</span> & <span>{minKavlifavoritt.ingredients[1].name}</span> & <span>{minKavlifavoritt.ingredients[2].name}</span>
														</div>
													</div>

													<div className='mask' style={{
														maskImage: `url('${images.mask}')`,
														WebkitMaskImage: `url('${images.mask}')`
													}}>

														<div className='color' style={{ backgroundColor: minKavlifavoritt.color }}></div>

														<div className='ingredient-01'>
															
															<img
																src={minKavlifavoritt.ingredients[0].image}
																alt={minKavlifavoritt.ingredients[0].name}
																className='img'
																data-ingredient={slugify(minKavlifavoritt.ingredients[0].name)}
															/>

														</div>

														<div className='ingredient-02'>

															<img
																src={minKavlifavoritt.ingredients[1].image}
																alt={minKavlifavoritt.ingredients[1].name}
																className='img'
																data-ingredient={slugify(minKavlifavoritt.ingredients[1].name)}
															/>

														</div>

														<div className='ingredient-03'>

															<img
																src={minKavlifavoritt.ingredients[2].image}
																alt={minKavlifavoritt.ingredients[2].name}
																className='img'
																data-ingredient={slugify(minKavlifavoritt.ingredients[2].name)}
															/>

														</div>

													</div>

												</div>

												<p className='text-smaller author'>
													{minKavlifavoritt.author}
												</p>

												<h3 className='h4 title'>
													{minKavlifavoritt.title}
												</h3>
											</a>

											<div className='icons text-small'>
												<button
													onClick={(e) => {
														toggleLike(e, minKavlifavoritt)
													}}
												>
													<span className='icons__item'>
														{contentsLiked?.includes(minKavlifavoritt.id) ? <GeneralStarFull /> : <GeneralStar variant='outline' />}
														<span>
															{minKavlifavoritt.likes ? minKavlifavoritt.likes : '0'}
														</span>
													</span>
												</button>


												<button
													onClick={(e) => {
														window.open(`${minKavlifavoritt.url?.href}#user-review-comments`, '_self')
													}}
												>
													<span className='icons__item'>
														<GeneralChat variant='outline' />
														<span>
															{minKavlifavoritt.comments ? minKavlifavoritt.comments : '0'}
														</span>
													</span>
												</button>

											</div>
											<a href={minKavlifavoritt.url?.href}>
												<div className='arrow'>
													<UxArrowRight />
												</div>

											</a>
										</div>
									))}
									
								</div>
							) : (searchTerm || selectedFilters.length > 0) && (
								<div className='min-kavli-grid__no-products'>
									<p className='h4'>
										{strings.noIdeasFound}
									</p>
								</div>
							)}

							{hasNextPage && (
								<div className='min-kavli-grid__load-more'>
									<Link
										text='Last inn flere idéer'
										onClick={handleLoadMoreMinKavlifavoritt}
										iconPosition='right'
										icon={<UxArrowDown />}
									/>
								</div>
							)}

						</div>

					</div>
				</Section>
			}
		</main>
	)
}